import { Injectable } from '@angular/core';

import { BehaviorSubject, concat, forkJoin, interval, Observable, of, Subject, Subscription, timer } from 'rxjs';
import { auditTime, catchError, toArray } from 'rxjs/operators';
import { addDays, startOfDay } from 'date-fns';

import { BaseLogging } from '@base/base-logging';
import {
    BaseSystemModel, CalendarCounters, Chat, ChatsCounters, ChatsCountersType, ChatsState, ChatType, EventAttendeeState, GroupsState, IntegrationsState, MentionsCounters, ObjectOperation,
    ObjectType, OLD_BaseSystemModel, OrgInfo, OrgsState, OrgStatusState, Person, PersonsState, ProjectsState, RolesState, SM,
    StateCacheItemType, StateCacheKeyType, SYSTEM_USER_ID, Tag, TagsState, TasksCounters, UserNotificationsState
} from '@models';
import { HttpApiError } from '@models/api/http-api-error';
import { ApiService } from './api.service';
import { AuthService } from './auth.service';
import { ICountersEvent, IObjectEvent, StompService } from './stomp.service';
import { StoreService } from './store.service';

const MAX_INVALIDATE_COUNT = 3;

@Injectable()
@Tag('CacheService')
export class CacheService extends BaseLogging {

    public readonly ready: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public readonly criticalErrors: BehaviorSubject<HttpApiError[] | null> = new BehaviorSubject<HttpApiError[] | null>(null);
    public readonly nonCriticalError: Subject<HttpApiError> = new Subject();

    private invalidating: boolean = false;
    private updating: boolean = false;
    private updateSubscription?: Subscription;
    private _CL: {
        [T in StateCacheKeyType]: {
            init: () => Observable<StateCacheItemType[T][] | undefined>,
            postInit?: (items: StateCacheItemType[T][]) => StateCacheItemType[T][],
            get?: (id: string) => Observable<StateCacheItemType[T]>,
            postGet?: (item: StateCacheItemType[T]) => StateCacheItemType[T],
            delete?: (item: StateCacheItemType[T]) => void,
            state: any,
            notRequired?: boolean,
            nrRetry?: boolean,
            valid?: boolean,
            error?: boolean,
        }
    } = {
        orgs: {
            init: () => this._auth.getOrgs(),
            postInit: orgs => {
                concat<OrgInfo[]>(...orgs.map(org => this._api.getOrgBillingInfo(org.id!))).pipe(toArray()).subscribe(ois => {
                    const limits = this._store.orgLimits.getValue();
                    ois.forEach(oi => limits[oi.id!] = oi);
                    this._store.orgLimits.next({ ...limits });
                });
                return orgs;
            },
            get: (id: string) => this._auth.getOrg(id),
            postGet: org => {
                if (org?.id) {
                    this._api.getOrgBillingInfo(org.id).subscribe(oi => {
                        if (oi?.id) {
                            const limits = this._store.orgLimits.getValue();
                            this._store.orgLimits.next({ ...limits, [oi.id]: oi });
                        }
                    });
                }
                return org;
            },
            state: OrgsState,
        },
        tags: {
            init: () => this._api.getTags(),
            get: (id: string) => this._api.getTag(id),
            delete: tag => {
                if (!tag.parentId) {
                    const state = this._store.getStateDirect('tags');
                    const ids: string[] = Object.values(state).filter(t => t.parentId == tag.id).map(t => t.id);
                    this._handleObjectEvent({ ids, msgId: 'internal', type: ObjectType.Tag, operation: ObjectOperation.Delete });
                }
            },
            state: TagsState,
        },
        chats: {
            init: () => this._api.getChatsList(),
            get: (id: string) => this._api.getChat(id),
            state: ChatsState,
            notRequired: true,
            nrRetry: true
        },
        roles: {
            init: () => this._auth.getRoles(),
            get: (id: string) => this._auth.getRole(id),
            state: RolesState,
        },
        groups: {
            init: () => this._auth.getGroups(),
            get: (id: string) => this._auth.getGroup(id),
            state: GroupsState,
        },
        status: {
            init: () => this._api.getUserStatuses(),
            state: OrgStatusState,
            notRequired: true
        },
        persons: {
            init: () => this._auth.getPersons(),
            get: (id: string) => this._auth.getPerson(id),
            state: PersonsState,
        },
        projects: {
            init: () => this._auth.getProjects(),
            get: (id: string) => this._auth.getProject(id),
            state: ProjectsState,
        },
        integrations: {
            init: () => this._api.getIntegrationSetups(),
            get: (id: string) => this._api.getIntegrationSetup(id),
            state: IntegrationsState,
            notRequired: true
        },
        notifications: {
            init: () => this._api.getNotifications(),
            state: UserNotificationsState,
            notRequired: true
        },
        // orgstatustypes: {
        //     init: () => this._api.getUserOrgStatusTypes(),
        //     get: (id: string) => this._api.getUserOrgStatusType(id),
        //     state: OrgStatusTypesState,
        //     notRequired: true
        // }
    }
    forUpdate: { [T in StateCacheKeyType]?: { [id: string]: number } } = {};
    update: Subject<void> = new Subject();
    nrRetries: { [T in StateCacheKeyType]?: { count: number, sub?: Subscription } } = {};

    private _invalidateCount: number = 0;
    private _start: number = Date.now();
    private _ready: number = 0;
    private _tick: number = 0;

    constructor(
        private _store: StoreService,
        private _auth: AuthService,
        private _api: ApiService,
        private _stomp: StompService,
    ) {
        super();
        this._stomp.onObject.subscribe(e => this._handleObject(e));
        this._stomp.onObjectEvent.subscribe(e => this._handleObjectEvent(e));
        this._stomp.onRefreshCountersEvent.subscribe(e => this.handleRefreshCountersEvent(e));
        this.update.pipe(auditTime(200)).subscribe(() => this.updateObjects());
        this._store.activeOrgId.subscribe(orgId => {
            if (orgId) {
                this.getTaskCounters();
                this.getMentionCounters();
                this.updateCalendarCounters();
                this._store.updateGrantedPrivs();
            }
        });
        this._store.syncLost.pipe(auditTime(5000)).subscribe(() => this.updateInBackground());
        this._store.invalidateCache.subscribe(v => {
            if (v == 'all') {
                this.invalidate();
            }
            else {
                this.invalidateCategory(v);
            }
        });
        this._store.updateGrantedPrivs();
        interval(15 * 60 * 1000).subscribe(() => this.updateCalendarCounters());
        this.checkSleep();
    }

    checkSleep(): void {
        this._tick = Date.now();
        // this._L('checkSleep', 'set timeout, _tick:', this._tick);
        setTimeout(() => {
            if (this._store.getStateDirect('user').isLoggedIn()) {
                const d = Math.trunc((Date.now() - this._tick) / 1000);
                const ds = Math.trunc((Date.now() - this._start) / 60000);
                // this._L('checkSleep', 'delta:', d, 'deltaStart:', ds);
                if (d > 55) {
                    if (ds > 600) {
                        window.location.reload();
                        return;
                    }
                    else {
                        this._L('checkSleep', 'wakeUp detected');
                        this._stomp.reconnect();
                        this._store.wakeUp.next();
                        // this._store.syncLost.next();
                    }
                }
            }
            this.checkSleep();
        }, 50000);
    }

    invalidate(): void {
        this._W('invalidate');
        if (this.invalidating) {
            return;
        }
        this._clearSubscriptions();
        this.invalidating = true;
        this.ready.next(false);
        this.criticalErrors.next(null);
        for (const cl of Object.keys(this._CL)) {
            const type: StateCacheKeyType = cl as StateCacheKeyType;
            this._CL[type].valid = false;
            this._CL[type].error = false;
        }
        this._invalidateCount = 0;
        this._invalidate();
    }

    updateInBackground(): void {
        this._W('updateInBackground', 'invalidating:', this.invalidating, 'updating:', this.updating);
        if (this.invalidating || this.updating) {
            return;
        }
        this._clearSubscriptions();
        this.updating = true;
        this.criticalErrors.next(null);
        this._invalidateCount = 0;
        this._invalidate(true);
    }

    private _clearSubscriptions(): void {
        if (this.updateSubscription) {
            this.updateSubscription.unsubscribe();
            this.updateSubscription = undefined;
        }
        Object.keys(this.nrRetries).forEach(type => {
            if (this.nrRetries[type as StateCacheKeyType]?.sub) {
                if (!this.nrRetries[type as StateCacheKeyType]?.sub?.closed) {
                    this.nrRetries[type as StateCacheKeyType]?.sub?.unsubscribe();
                }
                delete this.nrRetries[type as StateCacheKeyType];
            }
        });
    }

    invalidateCategory(type: StateCacheKeyType): void {
        (this._CL[type]!.init() as Observable<any>).subscribe({
            next: res => {
                if (res && this._CL[type]!.postInit) {
                    res = this._CL[type]!.postInit!(res);
                }
                const state = this._store.getStateDirect(type);
                this._store.setState(type, new this._CL[type]!.state({ ...state, modified: {}, lu: Date.now() }).parse({ items: this._parseResult(res) }));
                this._CL[type].valid = true;
            },
            error: err => this.nonCriticalError.next(err)
        });
    }

    private _invalidate(silent = false): void {
        if (!this._store.getStateDirect('user').isLoggedIn()) {
            return;
        }
        this._GC('_invalidate', 'iteration:', this._invalidateCount, 'silent:', silent);

        const reqs: { [id: string]: Observable<any> } = {};
        const ncReqs: { [id: string]: Observable<any> } = {};
        for (const cl of Object.keys(this._CL)) {
            const type: StateCacheKeyType = cl as StateCacheKeyType;
            this._L('_invalidate', type, 'valid:', !!this._CL[type].valid, 'error:', !!this._CL[type].error);
            if ((!this._CL[type].valid || silent) && (!this._CL[type].error || this._CL[type].nrRetry)) {
                this._L('_invalidate', type, 'CL:', this._CL[type]);
                const req = (this._CL[type]!.init() as Observable<any>).pipe(catchError(error => of({ __err: error })));
                if (silent || this._CL[type].notRequired) {
                    ncReqs[type] = req;
                }
                else {
                    reqs[type] = req;
                }
            }
        }

        forkJoin(reqs).subscribe(this._handleInvalidateResults(true, () => {
            this.getTaskCounters();
            this.getMentionCounters();
            this.updateUnreadChatsCounters();
        }));
        this.updateSubscription = forkJoin(ncReqs).subscribe(this._handleInvalidateResults(false));

        if (this._invalidateCount == 0) {
            this.getTaskCounters();
            this.getMentionCounters();
        }
        this._GE();
    }

    private _handleInvalidateResults(required: boolean, cb?: () => void): (results: any) => void {
        this._L('_handleInvalidateResults', 'required:', required);
        return (results: any) => {
            const userId = this._store.getStateDirect('user').userId;
            const errors: HttpApiError[] = [];
            for (const cl of Object.keys(results)) {
                const type: StateCacheKeyType = cl as StateCacheKeyType;
                if (results[type] && !results[type].__err) {
                    const state = this._store.getStateDirect(type);
                    if (results[cl] && this._CL[type]!.postInit) {
                        results[cl] = this._CL[type]!.postInit!(results[cl]);
                    }
                    const objs = new this._CL[type]!.state({ ...state, modified: {}, lu: Date.now() }).parse({ items: this._parseResult(results[cl]) });
                    this._store.setState(type, objs);
                    this._CL[type].valid = true;
                    if (type == 'chats') {
                        this.updateUnreadChatsCounters();
                    }
                    else if (type == 'roles' || type == 'groups' || (type == 'projects' && Object.values(objs.items).findIndex((p: any) => p?.managerId && p.managerId == userId) != -1)) {
                        this._store.updateGrantedPrivs();
                    }
                }
                else {
                    if (this._CL[type].notRequired) {
                        this._L('_handleInvalidateResults', type, 'notRequired, nrRetry:', this._CL[type].nrRetry, 'isLoggedIn:', this._store.getStateDirect('user').isLoggedIn());
                        if (this._CL[type].nrRetry && this._store.getStateDirect('user').isLoggedIn()) {
                            this._L('_handleInvalidateResults', type, 'nrRetries:', this.nrRetries[type]);
                            if (!this.nrRetries[type]) {
                                this.nrRetries[type] = { count: 1 };
                            }
                            if (this.nrRetries[type]!.count < 8) {
                                this.nrRetries[type]!.count = this.nrRetries[type]!.count * 2;
                            }
                            this.nrRetries[type]!.sub = timer(this.nrRetries[type]!.count * 1000).subscribe(
                                () => forkJoin({
                                    [type]: (this._CL[type]!.init() as Observable<any>).pipe(catchError(error => of({ __err: error })))
                                }).subscribe(this._handleInvalidateResults(false, cb))
                            );
                        }
                        else {
                            this._CL[type].error = results[type].__err;
                            this.nonCriticalError.next(results[type].__err);
                        }
                    }
                    else {
                        errors.push(results[type].__err);
                    }
                }
            }
            const user = this._store.getStateDirect('persons').items[userId!];
            if (user && user.id == userId) {
                this._store.patchState('user', { user });
                this.checkOrgs(user);
                this._store.updateGrantedPrivs();
            }
            if (required) {
                this.invalidating = false;
                if (errors.length == 0) {
                    this._ready = Date.now();
                    this.ready.next(true);
                    this.criticalErrors.next(null);
                }
                else {
                    if (++this._invalidateCount > MAX_INVALIDATE_COUNT) {
                        this.criticalErrors.next(errors);
                    }
                    else {
                        setTimeout(() => this._invalidate(), 2000 * this._invalidateCount);
                    }
                }
            }
            this.updating = false;
            cb?.();
        }
    }

    injectEvent(e: IObjectEvent): void {
        this._handleObjectEvent(e);
    }

    updateObjects(): void {
        const forUpdate = this.forUpdate;
        this.forUpdate = {};
        for (const t of Object.keys(forUpdate)) {
            const type: StateCacheKeyType = t as StateCacheKeyType;
            const userId = this._store.getStateDirect('user').userId;
            const fu = forUpdate[type];
            const ids = fu ? Object.keys(fu) : [];
            if (ids.length > 0) {
                if (this._CL[type]!.get) {
                    const reqs: Observable<any>[] = [];
                    for (const id of ids) {
                        reqs.push((this._CL[type]!.get!(id) as Observable<any>).pipe(catchError(error => of({ __err: error }))));
                    }
                    forkJoin(reqs).subscribe({
                        next: objs => {
                            const state = this._store.getStateDirect(type);
                            if (state) {
                                for (let obj of objs) {
                                    if (obj && !obj.__err) {
                                        if (type == 'chats' && obj.type == ChatType.Task) {
                                            continue;
                                        }
                                        if (this._CL[type]!.postGet) {
                                            obj = this._CL[type]!.postGet!(obj);
                                        }
                                        const old: any = state.items[obj.id];
                                        state.items[obj.id] = obj;
                                        state.modified[obj.id] = Date.now();
                                        if (type == 'persons' && userId && obj.id == userId) {
                                            this._store.patchState('user', { user: obj });
                                            this.checkOrgs(obj);
                                            this._store.updateGrantedPrivs();
                                        }
                                        else if (type == 'chats') {
                                            this.updateUnreadChatsCounters();
                                        }
                                        else if (type == 'roles' || type == 'groups') {
                                            this._store.updateGrantedPrivs();
                                        }
                                        else if (type == 'projects') {
                                            if (
                                                (!old || (old.managerId != userId && obj.managerId == userId))
                                                || (old.managerId == userId && obj.managerId != userId)
                                            ) {
                                                this._store.updateGrantedPrivs();
                                            }
                                        }
                                    }
                                }
                                state.lu = Date.now();
                                this._store.setState(type, state);
                            }
                            else {
                                this._W('updateObjects', 'state is undefined, type:', type)
                            }
                        },
                        error: err => this._W('updateObjects', 'error getting object:', err)
                    });
                }
                else {
                    (this._CL[type]!.init() as Observable<any>).subscribe(
                        result => {
                            const items = this._parseResult(result);
                            const dt = Date.now();
                            const modified: SM<number> = {};
                            Object.keys(items).forEach(id => modified[id] = dt);
                            this._store.setState(type, new this._CL[type]!.state({ items, modified, lu: Date.now() }));
                        }
                    );
                }
            }
        }
    }

    private _parseResult<T extends { id?: string }>(list: T[]): { [id: string]: T } {
        const items: { [id: string]: T } = {};
        for (const it of list) {
            if (it && it.id) {
                items[it.id] = it;
            }
        }
        return items;
    }

    private _handleObject(obj: BaseSystemModel | OLD_BaseSystemModel): void {
        // this._L('_handleObject', obj);
        if (!obj?._type || !obj?.id) {
            return;
        }
        const type: StateCacheKeyType = obj._type == 'orgstatus' ? 'status' : obj._type + 's' as any;
        if (!this.ready.getValue() || !this._CL[type]) {
            return;
        }
        const state = this._store.getStateDirect(type);
        if (state) {
            if (type == 'orgs' && !state.items[obj.id]) {
                this._stomp.subscribeToOrg(obj.id);
            }
            state.items[obj.id] = obj as any;
            state.modified[obj.id] = Date.now();
            const nst = new this._CL[type].state({ items: state.items, modified: state.modified, lu: Date.now() });
            this._store.setState(type, nst);
            // this._L('_handleObject', `setState[${type}]:`, nst);
        }
    }

    private _handleObjectEvent(e: IObjectEvent): void {
        // this._L('_handleObjectEvent', e);
        if (!e || !e.type) {
            return;
        }
        if (e.type == ObjectType.CalendarEvent) {
            this.updateCalendarCounters();
        }
        const type: StateCacheKeyType = e.type == 'orgstatus'
            ? 'status'
            : (e.type == 'integrationsetup' ? 'integrations' : e.type + 's' as any);
        if (!this.ready.getValue() || !this._CL[type]) {
            return;
        }
        if (type == 'orgs') {
            if (e.operation == ObjectOperation.Create) {
                e.ids.forEach(id => this._stomp.subscribeToOrg(id));
            }
            else if (e.operation == ObjectOperation.Delete) {
                e.ids.forEach(id => this._stomp.unsubscribeFromOrg(id));
            }
        }
        if (e.operation == ObjectOperation.Create || e.operation == ObjectOperation.Update) {
            for (const id of e.ids) {
                if (!this.forUpdate[type]) {
                    this.forUpdate[type] = {};
                }
                this.forUpdate[type]![id] = 1;
            }
            this.update.next();
        }
        else if (e.operation == ObjectOperation.Delete) {
            const state = this._store.getStateDirect(type);
            if (state) {
                for (const oid of e.ids) {
                    delete state.items[oid];
                    state.lu = Date.now();
                    this._store.setState(type, state);
                }
            }
            if (e.type == ObjectType.ChatMention) {
                this.updateUnreadChatsCounters();
            }
        }
    }

    private handleRefreshCountersEvent(e: ICountersEvent): void {
        if (e.type == ObjectType.Task) {
            this.getTaskCounters();
        }
        else if (e.type == ObjectType.ChatMention) {
            this.getMentionCounters();
        }
    }

    private getTaskCounters(): void {
        const activeOrgId = this._store.activeOrgId.getValue();
        if (activeOrgId && activeOrgId != SYSTEM_USER_ID) {
            this._api.getTaskCounters(activeOrgId).subscribe(counters => this.updateUnreadTasksCounters(counters));
        }
    }

    private getMentionCounters(): void {
        const activeOrgId = this._store.activeOrgId.getValue();
        if (activeOrgId && activeOrgId != SYSTEM_USER_ID) {
            this._api.getChatMentionsCounters(activeOrgId).subscribe(counters => this.updateUnreadMentionsCounters(counters));
        }
    }

    private updateUnreadTasksCounters(counters: TasksCounters): void {
        this._store.patchState('counters', { tasks: counters });
    }

    private updateUnreadChatsCounters(): void {
        const chats = this._store.getState('chats').items;
        const counters = new ChatsCounters();
        const userId = this._store.getStateDirect('user').userId;
        const activeOrgId = this._store.activeOrgId.getValue();
        Object.values(chats).forEach(ch => {
            if (ch.orgId == activeOrgId && ch._membersMap[userId!]) {
                counters.chatsTotal++;
                const cct = this.getCCT(ch);
                if (cct && ch.type != null && (ch.thread.unreadCount > 0 || ch.hasUnreadThreads) && !ch.muted) {
                    counters.unreadChatsTotal++;
                    if (counters.unreadChats[cct] == null) {
                        counters.unreadChats[cct] = 0;
                    }
                    if (counters.unreadMessages[cct] == null) {
                        counters.unreadMessages[cct] = 0;
                    }
                    if (counters.unreadThreads[cct] == null) {
                        counters.unreadThreads[cct] = 0;
                    }
                    counters.unreadMessages[cct] += ch.thread.unreadCount;
                    counters.unreadChats[cct]++;
                    if (ch.hasUnreadThreads) {
                        counters.unreadThreads[cct]++;
                        counters.unreadThreadsTotal++;
                    }
                }
            }

        });
        this._store.patchState('counters', { chats: counters });
    }

    private getCCT(ch: Chat): ChatsCountersType {
        if (!ch) {
            return ChatsCountersType.Unknown;
        }
        if (ch.type == ChatType.Direct) {
            return ChatsCountersType.Direct;
        }
        if (ch.type == ChatType.Public || ch.type == ChatType.Private) {
            return ch.pluginId ? ChatsCountersType.Plugin : ChatsCountersType.Group;
        }
        return ChatsCountersType.Unknown
    }

    private updateUnreadMentionsCounters(counters: MentionsCounters): void {
        this._store.patchState('counters', { mentions: counters });
    }

    private checkOrgs(user: Person): void {
        const activeOrgId = this._store.activeOrgId.getValue();
        if (user && activeOrgId && activeOrgId != SYSTEM_USER_ID && !user._orgMap[activeOrgId]) {
            this._store.setActiveOrg(user.orgs?.length ? user.orgs[0].id : SYSTEM_USER_ID);
        }
    }

    private updateCalendarCounters(): void {
        const dt = new Date();
        const since = startOfDay(dt);
        const till = addDays(since, 1);
        this._api.getCalendarEvents(since, till).subscribe(events => {
            const userId = this._store.getStateDirect('user').userId;
            const activeOrgId = this._store.activeOrgId.getValue();
            const aoEvents = (events || []).filter(e => e.orgId == activeOrgId || !e.orgId);
            aoEvents.forEach(e => e.updateState(userId));
            const upcoming = aoEvents.filter(e => e.attState != EventAttendeeState.Rejected && e.end && e.end > dt).length;
            this._store.patchState('counters', { calendar: new CalendarCounters({ total: aoEvents.length, upcoming }) });
        });
    }

}
